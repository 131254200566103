export const familyGroupNames = {
  ALL: 'ALL',
  EVALUATE: 'EVALUATE',
  i3: 'i3',
  i3en: 'i3en',
  n2: 'n2',
  i4i: 'i4i',
};

export const CLUSTER_WIZARD_PARAMS = {
  DEAL: 'deal',
};

export const USER_API_INTERFACE = {
  CQL: 'CQL',
  ALTERNATOR: 'ALTERNATOR',
};

export const BROADCAST_TYPE = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
};

export const CLIENT_TO_NODE_ENCRYPTION_MODE = {
  ENCRYPTED: 'encrypted',
  UNENCRYPTED: 'unencrypted',
  BOTH: 'both',
};
export const CLIENT_TO_NODE_ENCRYPTION_MODES = [
  CLIENT_TO_NODE_ENCRYPTION_MODE.ENCRYPTED,
  CLIENT_TO_NODE_ENCRYPTION_MODE.UNENCRYPTED,
  CLIENT_TO_NODE_ENCRYPTION_MODE.BOTH,
];

export const DEFAULT_OWNER_NAME = 'Scylla';
export const CUSTOM_OWNER_NAME = 'Account';

export const ALTERNATOR_ISOLATION_POLICY_NAMES = {
  FORBID: 'forbid',
  ONLY: 'only_rmw_uses_lwt',
  ALWAYS: 'always',
};

export const ALTERNATOR_ISOLATION_POLICIES = {
  [ALTERNATOR_ISOLATION_POLICY_NAMES.FORBID]: {
    name: 'Forbid RMW',
    description:
      'Forbids write requests which require a read before a write. It does not use LWT and provides the best performance. Will return an error on UpdateItem with a ConditionExpression.',
  },
  [ALTERNATOR_ISOLATION_POLICY_NAMES.ONLY]: {
    name: 'Only RMW',
    description:
      'This mode uses LWT only for updates that require read-modify-write, and used normal quorum writes for write-only updates. Use this setting only if you do not mix read-modify-write and write-only updates to the same item, concurrently.',
  },
  [ALTERNATOR_ISOLATION_POLICY_NAMES.ALWAYS]: {
    name: 'Always',
    description:
      'Performs every write operation, even those that do not need a read before the write, as an LWT. This is the slowest choice, guaranteed to work correctly for every workload.',
  },
};

export const CLOUD_PROVIDER_NAME_BY_ID = {
  1: 'AWS',
  2: 'GCP',
};

export const CLOUD_PROVIDER_ID_BY_NAME = {
  AWS: 1,
  GCP: 2,
};

export const COUNTRY_GROUPS = {
  US: 2,
  NONUS: 1,
};

export const ALLOWED_IPS_LIMIT = 40;
export const DEFAULT_CIDR_RANGE = 16;

export const INSTANCE_TYPE_ENVIRONMENT = {
  PRODUCTION: 'PRODUCTION',
  DEVELOPMENT: 'DEVELOPMENT',
};

export const CONNECTION_STATUS = {
  ENABLED: 'Enabled',
  ACTIVE: 'Active',
  DISABLED: 'Disabled',
  NOT_SET: 'Not Set',
  CREATING: 'Creating',
  PENDING: 'Pending',
};

export const SORT_DIRECTIONS = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

export const PROVISIONING_TYPE = {
  DEDICATED_VM: 'dedicated-vm',
};

export const DEAL_TYPES = {
  /*
  FREE_TRIAL is used only for the url param as a user should see the FREE_TRIAL wording,
  but ?deal=FREE_TRIAL means that we are using the FREE_TIER backend deal.
  Ideally should be renamed on the backend to FREE_TRIAL to avoid confusion.
  */
  FREE_TRIAL: 'FREE_TRIAL',
  FREE_TIER: 'FREE_TIER',
  JUMP_START: 'JUMP_START',
};

export const ENCRYPTION_MODE = {
  NO_ENCRYPTION: 'NO_ENCRYPTION',
  SCYLLA_KEY: 'SCYLLA_KEY',
  CUSTOMER_KEY: 'CUSTOMER_KEY',
};

export const KEY_PROVIDERS = {
  [ENCRYPTION_MODE.SCYLLA_KEY]: 'scylla-aws',
  [ENCRYPTION_MODE.CUSTOMER_KEY]: 'aws',
};

export const VALID_PRICING_PLANS = {
  standart: 'Standart',
  pro: 'Professional',
  premium: 'Premium',
};

/**
 * trim long cluster names at this value (presentation only)
 */
export const TRIM_CLUSTER_NAME_AT = 30;

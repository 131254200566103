import pick from 'lodash/fp/pick';

import * as parsers from 'services/resources/parsers';
import { createServerResource } from 'services/server-resource';

const apiUrl = process.env.apiUrl;

export const createNewCluster = createServerResource(
  'post',
  `${apiUrl}/account/{{accountId}}/cluster`,
  {
    inputMap: {
      clusterName: 'clusterName',
      scyllaVersionId: 'scyllaVersionId',
      cidrBlock: 'cidrBlock',
      broadcastType: 'broadcastType',
      allowedIPs: 'allowedIPs',
      cloudProviderId: 'cloudProviderId',
      regionId: 'regionId',
      instanceId: 'instanceId',
      replicationFactor: 'replicationFactor',
      numberOfNodes: 'numberOfNodes',
      promProxy: 'promProxy',
      accountCredentialId: 'accountCredentialId',
      freeTier: 'freeTier',
      jumpStart: 'jumpStart',
      alternatorWriteIsolation: 'alternatorWriteIsolation',
      userApiInterface: 'userApiInterface',
      enableDnsAssociation: 'enableDnsAssociation',
      expiration: 'expiration',
      pu: 'pu',
      encryptionAtRest: 'encryptionAtRest',
      maintenanceWindows: 'maintenanceWindows',
    },
    parsers: [
      parsers.errorParser,
      ({ data }) => data,
      pick(['requestId', 'fields']),
    ],
  }
);

export const deleteCluster = createServerResource(
  'post',
  `${apiUrl}/account/{{accountId}}/cluster/{{clusterId}}/delete`,
  {
    inputMap: {
      clusterName: 'clusterName',
    },
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const resizeCluster = createServerResource(
  'post',
  `${apiUrl}/account/{{accountId}}/cluster/{{clusterId}}/resize`,
  {
    inputMap: {
      dcNodes: 'dcNodes',
    },
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const extractClusterMetrics = createServerResource(
  'post',
  `${apiUrl}/account/{{accountId}}/cluster/{{clusterId}}/promproxy/request`,
  {
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const disableClusterMetrics = createServerResource(
  'post',
  `${apiUrl}/account/{{accountId}}/cluster/{{clusterId}}/promproxy/disable`,
  {
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const addClusterDataCenter = createServerResource(
  'post',
  `${apiUrl}/account/{{accountId}}/cluster/{{clusterId}}/dc`,
  {
    inputMap: {
      cloudProviderId: 'cloudProviderId',
      regionId: 'regionId',
      instanceId: 'instanceId',
      numberOfNodes: 'numberOfNodes',
      replicationFactor: 'replicationFactor',
      cidrBlock: 'cidrBlock',
    },
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const addClusterAllowedIp = createServerResource(
  'post',
  `${apiUrl}/account/{{accountId}}/cluster/{{clusterId}}/network/firewall/allowed`,
  {
    inputMap: {
      ipAddress: 'ipAddress',
    },
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const deleteClusterAllowedIp = createServerResource(
  'delete',
  `${apiUrl}/account/{{accountId}}/cluster/{{clusterId}}/network/firewall/allowed/{{ruleId}}`,
  {
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const grafanaMonitor = createServerResource(
  'post',
  `${apiUrl}/account/monitor`,
  {
    inputMap: {
      grafanaUrl: 'grafanaUrl',
    },
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const enableDns = createServerResource(
  'put',
  `${apiUrl}/account/{{accountId}}/cluster/{{clusterId}}/dns/enabled`,
  {
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const updateClusterName = createServerResource(
  'patch',
  `${apiUrl}/account/{{accountId}}/cluster/{{clusterId}}/name`,
  {
    inputMap: {
      name: 'name',
    },
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const updateClientEncryptionMode = createServerResource(
  'put',
  `${apiUrl}/account/{{accountId}}/cluster/{{clusterId}}/encryption/mode`,
  {
    inputMap: {
      mode: 'mode',
    },
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const getClusterCaCertificate = createServerResource(
  'get',
  `${apiUrl}/account/{{accountId}}/cluster/{{clusterId}}/certificate`,
  {
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const getAccountCaCertificate = createServerResource(
  'get',
  `${apiUrl}/account/{{accountId}}/certificate`,
  {
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const createClusterMaintenanceWindow = createServerResource(
  'post',
  `${apiUrl}/account/{{accountId}}/cluster/{{clusterId}}/maintenance`,
  {
    inputMap: {
      rrule: 'rrule',
      duration: 'duration',
    },
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const updateClusterMaintenanceWindow = createServerResource(
  'put',
  `${apiUrl}/account/{{accountId}}/cluster/{{clusterId}}/maintenance/{{maintenanceId}}`,
  {
    inputMap: {
      rrule: 'rrule',
      duration: 'duration',
    },
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const deleteClusterMaintenanceWindow = createServerResource(
  'delete',
  `${apiUrl}/account/{{accountId}}/cluster/{{clusterId}}/maintenance/{{maintenanceId}}`,
  {
    inputMap: {
      rrule: 'rrule',
      duration: 'duration',
    },
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const createTgwConnection = createServerResource(
  'post',
  `${apiUrl}/account/{{accountId}}/cluster/{{clusterId}}/network/vpc/connection`,
  {
    inputMap: {
      clusterDCID: 'clusterDCID',
      cidrList: 'cidrList',
      type: 'type',
      name: 'name',
      data: 'data',
    },
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

export const deleteTgwConnection = createServerResource(
  'delete',
  `${apiUrl}/account/{{accountId}}/cluster/{{clusterId}}/network/vpc/connection/{{connectionId}}`,
  {
    parsers: [parsers.errorParser, ({ data }) => data],
  }
);

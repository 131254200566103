/* server side validations general error */

export const errorCodes = {
  unauthorizedErrorCode: '000002',
  invalidAuthToken: '020001',
  operationInProgressErrorCode: '041303',
};

export const serverErrors = {
  // General
  [errorCodes.invalidAuthToken]: 'Invalid token',
  '000001':
    'Too many requests sent from your IP address. Please try again in a minute.',
  [errorCodes.unauthorizedErrorCode]:
    'You do not have the required permissions to perform this action',

  // Set Country
  '010401': 'Invalid request format',
  '010402':
    'You are not allowed to change to this country. Please contact Support',
  '010403': 'Error setting account country',

  // Cross cloud credentials
  '010501': 'Error getting account active credentials',
  '010601': 'Error sending cross account request',

  // Notification emails
  '010803': 'Account ID is missing',
  '010804': 'Invalid request payload',
  '010805': 'Error setting notifications email',
  '010806': 'Provided email is invalid',
  '010807': 'Account ID is missing',
  '010808': 'Error getting notifications email',

  // Account Usage
  '011001': 'Invalid query parameter',
  '011002': 'Invalid end/start query parameter, end after start',
  '011003': 'Invalid end/start query parameter, minimum 30 minutes timespan',
  '011004':
    'Unexpected error occurred when account usage. Please contact support ',

  // Auth
  '020002': 'Authentication error',
  '020005': 'Only Bearer authorization token is supported',
  '020006': 'Authentication denied',
  '020007': 'User operations are temporarily disabled. Please try again later.',

  // Register
  '020101': 'Invalid request format',
  '020102':
    'Unexpected error occurred when creating a user. Please contact support',
  '020104': 'Registration is restricted',
  '020201': 'Error creating user',
  '020202': 'Error verifying user',
  // Login
  '020301': 'Invalid request format',
  '020302': 'Unexpected error occurred when login. Please contact support',
  '020303': 'Please check your email and complete your registration',
  '020304': 'Email or password is incorrect, please try again',
  '020305': 'Too many failed login attempts, please try again in a few minutes',
  '020306':
    'Your password has expired. Please check your email for instructions',
  // Logout
  '020401': 'Invalid request',
  '020402': 'Unexpected error occurred when logout',
  // Info
  '020501': 'Error retrieving user information',

  // Reset Password (email field)
  '020601': 'Invalid request format',
  '020602':
    'Unexpected error occurred when reset password. Please contact support',
  '020701': 'Invalid request format',
  '020702':
    'Unexpected error occurred when reset password. Please contact support',

  // Reset Password (change new password)
  '020801': 'Invalid request format',
  '020802':
    'Unexpected error occurred when reset password. Please contact support',

  // Change Password
  '020901': 'Invalid request format',
  '020902':
    'Unexpected error occurred when changing password. Please contact support',
  '020903': 'Current password is incorrect',
  '020904':
    "To help protect your account, please choose a password that you haven't used before",
  '020905': 'New password is incorrect',

  // User monitor and metadata
  '021901': 'Invalid request format',

  // Billing Payment
  '030101': 'Invalid request format',
  '030102': 'Error adding billing card',
  '030201': 'Error retrieving billing info',

  // Create Cluster
  '040701': 'Invalid request format',
  '040702':
    'Unexpected error occurred when creating a cluster. Please contact support',
  '040703': 'A cluster with this name already exists, please use another name',
  '040704': 'Provided cluster network range is too small',
  '040705': 'Provided cluster network does not belong to a private network',
  '040706': 'Provided cluster network range is too big',
  '040707': 'Account is not eligible for free-tier',
  '040712':
    'Provided cluster name should be 2-63 characters, start and end with an alphanumeric character',
  '040713':
    'Provided cluster network CIDR block is reserved and cannot be used. Please use a different CIDR block',
  '040714':
    'Allowed IPs range is too big. CIDR range suffix must be at least /16',
  '040715': 'Account cloud provider is not supported for deferred billing info',
  '040716': 'Account is not active',
  '040717':
    'Account is not eligible for deferred billing info since billing is verified',
  '040718':
    'Account is not eligible for deferred billing since there is no active cloud account',
  '040719':
    'Deferred billing period has been already initiated for this account',
  '040720': 'The period allowing no billing information is expired',
  '040722': 'Account billing is not verified',
  '040723': 'Account does not have encryption at rest enabled',
  '040724': 'General error related to key service',
  '040725': "Invalid request's expiration format",

  // Delete Cluster
  '040801': 'Invalid request format',
  '040802':
    'Unexpected error occurred when deleting a cluster. Please contact support',
  '040803': 'Cluster name is invalid',

  // Resize Cluster
  '041001': 'Invalid request format',
  '041002':
    'Unexpected error occurred when resizing a cluster. Please contact support',
  '041006': 'Active resize request exists',
  '041007': 'Node count is not multiplication of Replication Factor',

  // Rename Cluster
  '044002': 'General error renaming the cluster',
  '044003': 'Cluster must be active to rename the cluster',
  '044004': 'Cluster name must be in a valid format',
  '044005': 'Cluster name is already used',

  // Prom proxy
  '041160': 'General error requesting prometheus proxy',
  '041171': 'Prometheus proxy tokens not found',

  // Add Allowed IP
  '040101': 'Invalid request format',
  '040102': 'Error adding a new firewall rule',
  '040103': 'The firewall rule already exists',
  '040104': 'Invalid firewall rule',
  '040105':
    'Allowed IPs range is too big. CIDR range suffix must be at least /16',

  // Cluster Connect
  '040201':
    'Unexpected error occurred when retrieving credentials. Please contact support.',

  // Cluster Create
  '040708': 'Invalid number of nodes for free tier cluster',
  '040709': 'Instance type not eligible for free tier cluster',
  '040710': 'Invalid billing status',
  '040711': 'Given service version requires AlternatorWriteIsolation',
  '040726': 'Too many maintenance windows',
  '040727': 'Maintenance window schedule rule format is invalid',
  '040728': 'Maintenance window schedule rule is invalid',
  '040729': 'Maintenance window duration is too short',
  '040730': 'Maintenance window time is out of working hours',
  '040731': 'Maintenance windows overlap with each other',
  '040732': 'Not enough maintenance windows',
  '040733': 'Invalid encryption at rest parameter',

  // Delete Allowed IP
  '040901': 'Error deleting the firewall allowed ip',
  '040902': 'Invalid firewall rule',

  // Cluster Resize
  '041004': 'Feature is not enabled on cluster',
  '041005':
    'Resizing by this number of nodes is not permitted. Please contact support for assistance',

  // Cluster VPC
  '041150':
    'Unexpected error occurred when retrieving cluster VPC list. Please contact support.',

  // Cluster encryption
  '041500': 'Unable to change encryption mode',

  // Cluster Usage
  '041601': 'Invalid query parameter',
  '041602': 'Invalid end/start query parameter, end after start',
  '041603': 'Invalid end/start query parameter, minimum 30 minutes timespan',
  '041604':
    'Unexpected error occurred when cluster usage. Please contact support ',

  // VPC Peering List
  '041120':
    'Unexpected error occurred when listing VPC peering connections. Please contact support.',

  // Adding VPC Peering
  '041101': 'Invalid request format',
  '041102':
    'The CIDR block address provided is already in use. It overlaps either with the target VPC or with an already established VPC peering connection',
  '041103': 'This VPC is already peered',
  '041104': 'The cluster does not have VPC peering enabled',
  '041105': 'The VPC ID you provided is invalid',
  '041106': 'The AWS Account ID you provided is invalid',
  '041107': 'Cluster has no VPC',
  '041108': 'Cluster has multiple VPC',
  '041109':
    'The provided Cluster network CIDR block is reserved and cannot be used. Please use a different CIDR block',
  '041111': 'General error creating VPC peering connection',
  '041112': 'Invalid firewall rule',
  '041113': 'CIDR range is too big, the prefix must be at least /16',
  '041114': 'Invalid request parameter',
  '041115': 'Missing peer region ID',
  '041116': 'Invalid request format',

  // Account switching
  '041118': 'Operation is not allowed',

  // Delete VPC Peering
  '041130':
    'Unexpected error occurred when deleting a VPC peering connection. Please contact support.',

  // VPC Peering Info
  '041142':
    'Unexpected error occurred when reading a VPC peering connection. Please contact support.',
  '041141': 'VPC peering connection not found',

  // DNS
  '041300': 'Unexpected error occurred when enabling dns',
  '041301': 'DNS support is already disabled on this cluster',
  '041302': 'DNS support is already enabled on this cluster',
  [errorCodes.operationInProgressErrorCode]:
    'There is another active DNS request in progress',

  // Maintenance windows
  '041400': 'Unable to retrieve windows information',
  '041401': 'Unable to retrieve cluster windows list',
  '041402': 'Bad request format, rrule is missing in request body',
  '041403': 'Bad request format, invalid rrule format',
  '041404': 'Bad request format, duration needs to be >= 0',
  '041405': 'Provided windows overlaps with other windows',
  '041406': 'Window outside of working hours',
  '041407': "Can't delete more windows, minimum required windows reached",
  '041408': "Can't create more windows, maximum allowed number reached",
  '041409': 'Unable to create maintenance window',
  '041410': 'Unable to update the maintenance window',
  '041411': 'Unable to delete maintenance window',
  '041412': 'Window outside of working hours',

  // Request Add DC
  '0420007': 'General error requesting to add data center',
  '0420008': 'Provided CIDR block overlaps with another data center',
  '0420009': 'Provided CIDR block overlaps with VPC peer',
  '0420010': 'Provided CIDR range is too small',
  '0420011': 'Provided CIDR does not belong to a private network',
  '0420012': 'Provided CIDR does not belong to a private network',
  '0420014':
    'Provided CIDR block is reserved and cannot be used. Please use a different CIDR block',

  // Rescale requests
  '0420015': 'General error creating datacenter rescale request',
  '0420017': 'The current and requested size are the same',
  '0420018': 'Free tier clusters cannot be rescaled',
  '0420019': 'Development clusters cannot be rescaled',
  '0420020': 'Node count must be a multiplication of RF',
  '0420021': 'Account billing is not verified',

  // Cluster Usage
  '0430001': 'Invalid query parameter',
  '0430002': 'Invalid end/start query parameter, end after start',
  '0430003': 'Invalid end/start query parameter, minimum 30 minutes timespan',
  '0430004':
    'Unexpected error occurred when cluster usage. Please contact support',

  // Cluster connections
  '043001': 'General error updating cluster connection',
  '043002': 'Invalid request format',
  '043003': 'Invalid request format',
  '043005': 'VPC peering not found',
  '043006': 'Property cidrlist is empty list',
  '043007': "Property cidrlist can't have more than 5 entries",
  '043008': 'Provided CIDRs overlaps reserved networks',
  '043009': 'Provided CIDRs overlaps existing cluster datacenter CIDR',
  '043010':
    'It is allowed to update only peering with status ACTIVE or INACTIVE',
  '043011':
    'Property status validation failed, it can only be "ACTIVE","INACTIVE"',
  '043012': 'Property status validation failed',

  // Cloud account
  '070101': 'Error listing the cloud accounts',
  '070201': 'Error creating a new cloud account',
  '070203': 'Invalid request format',
  '070204': 'Unsupported CloudProviderID',
  '070301': 'Error deleting the cloud accounts',
  '070303': 'The cloud account is used by one or more cluster DC',
  '070401': 'Error updating the cloud account',
  '070403': 'CloudRoleExternalID must match the existing value',
  '070404': 'BoundaryPolicyARN field AccountID must match AWSAccountID',
  '070405': 'CloudPolicyARN field AccountID must match AWSAccountID',
  '070406': 'CloudRoleARN field AccountID must match AWSAccountID',
  '070407': 'AWSAccountID must be a valid 12-digit account ID or be empty',
  '070408': 'BoundaryPolicyARN must be a valid ARN or be empty',
  '070409': 'BoundaryPolicyARN must be a policy',
  '070410': 'CloudPolicyARN must be a valid ARN or be empty',
  '070411': 'CloudPolicyARN must be a policy',
  '070412': 'CloudRoleARN must be a valid ARN or be empty',
  '070413': 'CloudRoleARN must be a role',
  '070414': 'The cloud account is used by one or more cluster DC',
  '070501': 'Error getting the AWS boundary IAM policy document',
  '070601': 'Error getting the AWS cloud IAM policy document',
  '070603': 'Invalid request format',
  '070604':
    'Cloud policy name must be between 1 and 64 characters, and use only word characters or symbols: +=,./@-',
  '070605': 'Unsupported cloud provider',
  '070606': 'The boundary policy ARN is not set',
  '070607': 'The AWS account ID is not set',
  '070701': 'Error checking the cloud account policies',
  '070703': 'The cloud account properties are not complete',
  '070801': 'Error getting the cloud account clusters',

  // Cluster Connection errors (VPC/TGW)
  '080001':
    'At least one of properties clusterVPCID or clusterDCID have to be specified',
  '080002': 'Invalid request format',
  '080003': 'Firewall rule already exist',
  '080004': "Can't find target VPC",
  '080005': "Can't find target VPC",
  '080006': 'Unknown cluster connection type',
  '080007': 'Property cidrList is empty list',
  '080008': 'Provided CIDRs overlaps reserved networks',
  '080009': 'Provided CIDRs overlaps existing cluster datacenter CIDR',
  '080010': 'Property data.ramARN validation failed',
  '080011': 'Property data.tgwID validation failed',
  '080012': 'Property data.customerVPCID validation failed',
  '080013': 'Property data.customerAccountID validation failed',
  '080014': 'Property data.customerNetworkID validation failed',
  '080015': 'Invalid request format',
  '080016': 'Invalid firewall rule',
  '080017': 'CIDR range is too big, the prefix must be at least /16',
  '080018': 'Invalid request parameter',
  '080019': 'Property name validation failed',
  '080020': 'General error creating cluster connections',
  '080021': "Property cidrlist can't have more than 5 entries",
  '081001': 'Failed to parse query parameter type',
  '081002': 'Failed to parse query parameter cluster-id',
  '081003': 'Failed to parse query parameter dc-id',
  '081004': 'Failed to parse query parameter vpc-id',
  '081005': 'General error listing cluster connections',
  '082001': 'Failed to parse connection',
  '082002': 'Cluster connection not found',
  '082003': 'General error getting cluster connection',
  '083001': 'Failed to parse connection',
  '083002': 'Cluster connection not found',
  '083003': 'General error deleting cluster connection',
  '084001': 'Failed to parse connection',
  '084002': 'Cluster connection not found',
  '084003': 'Property cidrList is empty list',
  '084004': 'Provided CIDRs overlaps reserved networks',
  '084005': 'Provided CIDRs overlaps existing cluster datacenter CIDR',
  '084006': 'Property data.ramARN validation failed',
  '084007': 'Property data.tgwID validation failed',
  '084008': 'Property data.customerVPCID validation failed',
  '084009': 'Property data.customerAccountID validation failed',
  '084010': 'Property data.customerNetworkID validation failed',
  '084011': 'Property name validation failed',
  '084012': 'Property status validation failed',
  '084013': 'Invalid request format',
  '084014': 'Invalid request format',
  '084015': 'General error updating cluster connection',

  // Custom Errors
  BILLING_TOKEN: 'There was an error submitting your billing information',
};

export function parseGeneralServerError(error = 'UNKNOWN_ERROR') {
  // can set actions.failure action with a custom string for client side validation
  let message, code;
  if (error in serverErrors) {
    message = serverErrors[error];
    code = error;
  } else if (error?.message in serverErrors) {
    message = serverErrors[error.message];
    code = error.message;
  } else if (error === 'UNKNOWN_ERROR') {
    message = 'Unexpected error. please try again.';
  } else {
    message = `Unexpected server error occurred (${
      error?.message ?? error
    }). Please contact support.`;
  }

  return {
    msg: message,
    traceId: error?.traceId,
    code,
  };
}

export function getErrorTraceId(errorWithTraceId) {
  return errorWithTraceId?.traceId;
}

export function getErrorMessage(errorWithTraceId) {
  return errorWithTraceId?.msg || 'Unexpected error. please try again.';
}

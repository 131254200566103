import { DeleteOutline, Add } from '@mui/icons-material';
import BuildIcon from '@mui/icons-material/BuildOutlined';
import { Typography, IconButton, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import ClusterName from 'components/common/ClusterName';
import UsedTimezone from 'components/common/UsedTimezone';
import AlertWithTraceId from 'components/common/alert-with-trace-id';
import MainModal, {
  MainModalHeader,
  MainModalContent,
  MainModalActions,
  MainModalActionButton,
} from 'components/common/main-modal';
import Skeleton from 'components/common/skeleton';
import withStyles from 'components/withStylesAdapter';
import { useClusterMaintenanceWindowsInfo } from 'hooks/maintenanceWindows';
import { rruleToWindow } from 'services/maintenance-windows';

import MaintenanceWindowItem from './item';

const withJss = withStyles(theme => ({
  description: {
    fontSize: theme.typography.pxToRem(14),
  },
  actionBtn: {
    border: `1px dashed ${theme.colors.mercury}`,
    borderRadius: theme.typography.pxToRem(12),
  },
  actionIcon: {
    width: theme.typography.pxToRem(16),
    height: theme.typography.pxToRem(16),
  },
  buttonsContainer: theme.extensions.buttonsContainer(theme),
  timezone: {
    display: 'flex',
    alignItems: 'center',
  },
  globe: {
    marginRight: theme.typography.pxToRem(8),
  },
}));

const emptyWindow = {
  day: '',
  hours: '',
};

const areWindowsEqual = (window1, window2) =>
  window1.id && window2.id
    ? window1.id === window2.id
    : window1.day === window2.day && window1.hours === window2.hours;

const windowKey = window => window?.day + window?.hours?.replaceAll(' ', '');

const windowId = window => window.id || windowKey(window);

export const isNewWindow = window => windowId(window) === windowKey(window);

const shouldShowAdd = (index, windows, newWindows, maxWindows) =>
  index === windows.length - 1 && index < maxWindows - 1 && !newWindows.length;

const shouldShowAddForNewWindow = (
  index,
  newWindows,
  windows,
  minWindows,
  maxWindows
) =>
  windows.length < maxWindows - 1 &&
  (index !== 0 || newWindows.length !== minWindows);

const shouldShowDelete = (index, minWindows) => index > minWindows - 1;

const shouldShowDeleteForNewWindow = (index, windows, newWindows, minWindows) =>
  index === newWindows.length - 1 &&
  windows.length + newWindows.length > minWindows;

function MaintenanceWindowModal({
  hideModal,
  classes,
  params: {
    clusterName,
    setMaintenance,
    clusterWindows,
    isClusterInfoLoading,
    clusterInfoError,
    isRequestInProgress,
  } = {},
}) {
  const theme = useTheme();
  const [windows, setWindows] = useState([]);
  const [newWindows, setNewWindows] = useState([]);

  const {
    isLoading: isMaintenanceInfoLoading,
    error: maintenanceInfoError,
    data: maintenanceInfo,
  } = useClusterMaintenanceWindowsInfo();

  const minWindows = maintenanceInfo?.minWindows;
  const maxWindows = maintenanceInfo?.maxWindows;

  useEffect(
    function setFetchedWindows() {
      if (clusterWindows && minWindows) {
        const parsedWindows = clusterWindows?.map(window => ({
          ...window,
          ...rruleToWindow(window.rrule, window.duration),
        }));
        setWindows(parsedWindows);

        const newWindowsLength = Math.max(minWindows - parsedWindows.length, 0);
        setNewWindows(Array(newWindowsLength).fill(emptyWindow));
      }
    },
    [clusterWindows, minWindows]
  );

  const windowOptions = maintenanceInfo?.timeSlices?.map(slice =>
    rruleToWindow(slice.rrule, slice.duration)
  );

  const availableWindows =
    windowOptions?.filter(
      option => !windows?.find(window => areWindowsEqual(window, option))
    ) || [];

  const addWindow = (window, newWindowIndex) => {
    const matchingWindow = availableWindows?.find(option =>
      areWindowsEqual(option, window)
    );

    if (matchingWindow) {
      setWindows(prevWindows => [
        ...prevWindows,
        { id: windowId(window), ...matchingWindow },
      ]);
      setNewWindows(prevWindows => [
        ...prevWindows.slice(0, newWindowIndex),
        ...prevWindows.slice(newWindowIndex + 1, prevWindows.length),
      ]);
    } else {
      setNewWindows(prevWindows => [
        ...prevWindows.slice(0, newWindowIndex),
        { day: window.day, hours: '' },
        ...prevWindows.slice(newWindowIndex + 1, prevWindows.length),
      ]);
    }
  };

  const setNewWindow = (window, index) => {
    if (window.day && window.hours) {
      addWindow(window, index);
    } else {
      setNewWindows(prevWindows => [
        ...prevWindows.slice(0, index),
        window,
        ...prevWindows.slice(index + 1, prevWindows.length),
      ]);
    }
  };

  const deleteWindow = window =>
    setWindows(prevWindows =>
      prevWindows.filter(w => !areWindowsEqual(w, window))
    );

  const deleteNewWindow = () => setNewWindows([]);

  const updateWindow = window => {
    const matchingWindow = availableWindows?.find(option =>
      areWindowsEqual(option, window)
    );

    if (matchingWindow) {
      setWindows(prevWindows =>
        prevWindows.map(prevWindow => {
          return areWindowsEqual(prevWindow, window)
            ? { ...prevWindow, ...matchingWindow }
            : prevWindow;
        })
      );
    } else {
      const newWindow = { id: windowId(window), day: window.day, hours: '' };
      setWindows(prevWindows =>
        prevWindows.map(prevWindow => {
          return areWindowsEqual(prevWindow, window) ? newWindow : prevWindow;
        })
      );
    }
  };

  const error = clusterInfoError || maintenanceInfoError;
  const isLoading = isClusterInfoLoading || isMaintenanceInfoLoading;

  const everyWindowIsValid = windows?.every(
    window => window?.day && window?.hours
  );

  const isSetDisabled =
    !everyWindowIsValid || !windows?.length || !!newWindows?.length;

  return (
    <MainModal onClose={hideModal} fullWidth maxWidth="sm">
      <MainModalHeader
        onClose={hideModal}
        HelperIcon={<BuildIcon fontSize="medium" />}
        helperIconColor={theme.colors.mineShaft}
      >
        {isLoading ? (
          <Skeleton isLoading={true} width="100%" />
        ) : (
          <span>
            Set Preferred Maintenance Windows for{' '}
            <ClusterName name={clusterName} />
          </span>
        )}
      </MainModalHeader>
      <MainModalContent>
        <AlertWithTraceId error={error} id="maintenanceWindowsModalError" />
        <Skeleton isLoading={isLoading}>
          <Typography
            variant="caption"
            paragraph
            className={classes.description}
            id="maint-modal-desc"
          >
            ScyllaDB Cloud periodically performs maintenance work on your
            cluster, including database upgrades and functionality enhancements.
          </Typography>
          <Typography
            variant="caption"
            paragraph
            className={classes.description}
          >
            Scheduled maintenance will start within the hours you select. Some
            urgent maintenance activities (e.g. security patches) cannot wait
            for your selected window and ScyllaDB Cloud will start those
            activities when needed.
          </Typography>
          <Typography
            variant="caption"
            paragraph
            className={classes.description}
          >
            <b>Maintenance work has no affect on SLA and/or availability.</b>
          </Typography>
          <UsedTimezone />
        </Skeleton>

        {windowOptions &&
          windows?.map((window, index) => (
            <MaintenanceWindowItem
              window={window}
              key={windowId(window)}
              onChange={updateWindow}
              options={availableWindows}
            >
              {shouldShowDelete(index, minWindows) && (
                <IconButton
                  className={classes.actionBtn}
                  onClick={() => deleteWindow(window)}
                  id="maint-modal-delete-item"
                  data-testid="maint-delete-item"
                >
                  <DeleteOutline className={classes.actionIcon} />
                </IconButton>
              )}
              {shouldShowAdd(index, windows, newWindows, maxWindows) && (
                <IconButton
                  className={classes.actionBtn}
                  onClick={() =>
                    setNewWindows(prevWindows => [...prevWindows, emptyWindow])
                  }
                  id="maint-modal-add-item"
                  data-testid="maint-add-item"
                >
                  <Add className={classes.actionIcon} />
                </IconButton>
              )}
            </MaintenanceWindowItem>
          ))}
        {windowOptions &&
          newWindows?.map((newWindow, index) => (
            <MaintenanceWindowItem
              window={newWindow}
              key={`new-window-${newWindow.day}-${newWindow.hours}-${index}`}
              onChange={changedWindow => setNewWindow(changedWindow, index)}
              options={availableWindows}
            >
              {shouldShowDeleteForNewWindow(
                index,
                windows,
                newWindows,
                minWindows
              ) && (
                <IconButton
                  className={classes.actionBtn}
                  onClick={deleteNewWindow}
                  id="maint-modal-delete-item"
                  data-testid="maint-delete-item"
                >
                  <DeleteOutline className={classes.actionIcon} />
                </IconButton>
              )}
              {shouldShowAddForNewWindow(
                index,
                newWindows,
                windows,
                minWindows,
                maxWindows
              ) && (
                <IconButton
                  className={classes.actionBtn}
                  onClick={() => addWindow(newWindow, index)}
                  disabled={!newWindow.day || !newWindow.hours}
                  id="maint-modal-add-item"
                  data-testid="maint-add-item"
                >
                  <Add className={classes.actionIcon} />
                </IconButton>
              )}
            </MaintenanceWindowItem>
          ))}
      </MainModalContent>

      <MainModalActions>
        <div className={classes.buttonsContainer}>
          <MainModalActionButton
            id="set-maint-btn"
            type={MainModalActionButton.Types.MAJOR}
            onClick={async () => {
              await setMaintenance(windows);
              hideModal();
            }}
            isLoading={isRequestInProgress}
            disabled={isSetDisabled}
          >
            SET MAINTENANCE
          </MainModalActionButton>
          <MainModalActionButton
            id="cancel-maint-btn"
            type={MainModalActionButton.Types.MINOR}
            onClick={hideModal}
          >
            CANCEL
          </MainModalActionButton>
        </div>
      </MainModalActions>
    </MainModal>
  );
}

MaintenanceWindowModal.propTypes = {
  classes: PropTypes.shape({}),
  params: PropTypes.shape({
    clusterName: PropTypes.string,
    setMaintenance: PropTypes.func.isRequired,
    clusterWindows: PropTypes.array,
    isClusterInfoLoading: PropTypes.bool,
    clusterInfoError: PropTypes.object,
    isRequestInProgress: PropTypes.bool,
  }),
  hideModal: PropTypes.func.isRequired,
};

export default withJss(MaintenanceWindowModal);

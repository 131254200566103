import { Box, Typography } from '@mui/material';

import SingleDcResizeBox from 'components/common/modals/ResizeCluster/SingleDcResizeBox';
import withStyles from 'components/withStylesAdapter';

const withJss = withStyles(theme => ({
  infoText: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 400,
  },
}));

function ResizeClusterModalContent({
  dataCenters,
  nodesPerDc,
  newNodesPerDc,
  newInstancePerDc,
  updateNewNodesCount,
  updateNewInstanceType,
  disableInputs,
  classes,
}) {
  return (
    <Box>
      <Typography className={classes.infoText}>
        You can increase/decrease the number of nodes of a cluster or modify the
        instance type. Note that performing a resize change can sometimes take
        between a few hours and a full day.
      </Typography>
      {dataCenters.map(dc => (
        <SingleDcResizeBox
          dataCenter={dc}
          currentNodesCount={nodesPerDc[dc.id]}
          newNodesCount={newNodesPerDc[dc.id]}
          newInstanceId={newInstancePerDc[dc.id]}
          updateNewNodesCount={updateNewNodesCount}
          updateNewInstanceType={updateNewInstanceType}
          disableInputs={disableInputs}
          key={dc.id}
        />
      ))}
    </Box>
  );
}

export default withJss(ResizeClusterModalContent);
